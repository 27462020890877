import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-logger";
export class AuthGuardService {
    constructor(authService, router, logger) {
        this.authService = authService;
        this.router = router;
        this.logger = logger;
    }
    canActivate() {
        this.logger.debug(this.constructor.name + ' starting canActivate>');
        if (this.authService.getIsUserAuthorized()) {
            this.logger.debug(this.constructor.name + ' session considered valid');
            return true;
        }
        this.logger.debug(this.constructor.name + ' session considered invalid');
        this.router.navigate(['/auth']);
        return false;
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.NGXLogger)); }, token: AuthGuardService, providedIn: "root" });
