import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionBillRoutingModule } from './collection-bill-routing.module';
import { NgxCurrencyModule } from 'ngx-currency';

import { CollectionBillComponent } from './collection-bill/collection-bill.component';
import { CollectionBillSearchComponent } from './collection-bill-search/collection-bill-search.component';
import { CollectionBillCreateComponent } from './collection-bill-create/collection-bill-create.component';
import { DigitOnlyDirective } from './classes/digit-only.directive';
import { DateInputDirective } from './classes/date-input.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DigitsWithSeparatorDirective } from './classes/digits-with-separator.directive';
import { ThousandSeparatorPipe } from './classes/thousand-separator.pipe';

@NgModule({
  declarations: [
    CollectionBillSearchComponent,
    CollectionBillCreateComponent,
    CollectionBillComponent,
    DigitOnlyDirective,
    DateInputDirective,
    DigitsWithSeparatorDirective,
    ThousandSeparatorPipe],
  imports: [
    CommonModule,
    CollectionBillRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    FlexLayoutModule
  ]
})
export class CollectionBillModule { }
