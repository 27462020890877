import { MatSnackBarConfig } from '@angular/material';
import { SnackBarMessage } from '../models/snack-bar-message';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class SnackBarService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.messageQueue = [];
        this.isSnackBarVisible = false;
    }
    /**
     * Adds message to queue to be shown in snack bar to user
     * Basic usage:
     *  - import SnackBarService
     *  - inject service in constructor: private snackBar: SnackBarService
     *  - call service in your code: this.snackBar.addMessage('Message for user...');
     *
     * By default the message appears for 5 seconds.
     *
     * @param message Message to display to user in snack bar
     * @param action tbd - action description
     * @param config tbd - config description
     */
    addMessage(message, action, config) {
        if (!config) {
            config = new MatSnackBarConfig();
            config.duration = 2500;
        }
        const snackBarMessage = new SnackBarMessage();
        snackBarMessage.message = message;
        snackBarMessage.config = config;
        this.messageQueue.push(snackBarMessage);
        if (!this.isSnackBarVisible) {
            this.showMessage();
        }
    }
    showMessage() {
        if (this.messageQueue.length === 0) {
            return;
        }
        const snackBarMessage = this.messageQueue.shift();
        this.isSnackBarVisible = true;
        setTimeout(() => this.showSnackBar(snackBarMessage));
    }
    showSnackBar(snackBarMessage) {
        this.snackBarRef = this.snackBar.open(snackBarMessage.message, snackBarMessage.action, snackBarMessage.config);
        this.snackBarRef.afterDismissed().subscribe(() => {
            this.isSnackBarVisible = false;
            this.showMessage();
        });
    }
}
SnackBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SnackBarService, providedIn: "root" });
