import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CollectionBillSearchComponent } from '../collection-bill-search/collection-bill-search.component';

@Component({
  selector: 'app-collection-bill',
  templateUrl: './collection-bill.component.html'
})
export class CollectionBillComponent implements OnInit, OnDestroy {

  public searchForm: FormGroup;
  private isSearching$ = new BehaviorSubject(false);
  public isSearchButtonDisabled = false;
  public isCreateButtonDisabled = false;
  public isSearchResultTableHidden = true;
  private searchSuccessMessage: string;
  public searchErrorMessage: string;

  @ViewChild(CollectionBillSearchComponent, { static: true }) searchComponent: CollectionBillSearchComponent;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.isSearchResultTableHidden = true;

    this.searchForm = this.fb.group({
      insuranceContractNumber: ['', [Validators.required, Validators.pattern('^\\d*$')]]
    });

    this.isSearching$.subscribe(
      value => {
        this.isSearchButtonDisabled = value;
        this.isCreateButtonDisabled = value;
      }
    );
  }

  ngOnDestroy() {
    this.isSearching$.unsubscribe();
  }

  public searchContract() {
    if (this.searchForm.invalid) {
      return;
    }

    this.isSearching$.next(true);
    this.searchComponent.startSearch(this.searchForm.value.insuranceContractNumber);
  }

  public getSearchSuccess(message: string) {
    this.searchSuccessMessage = message;
    this.isSearchResultTableHidden = false;
    this.isSearching$.next(false);
  }

  public getSearchError(message: string) {
    this.isSearchResultTableHidden = true;
    this.searchErrorMessage = message;
  }

  public createCollectionBill() {
    this.router.navigate(['/create']);
  }

}
