import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppConfig } from '../models/app-config';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  configLocation = environment.configLocation;
  configuration: AppConfig;

  httpClient: HttpClient;

  constructor(
    // We need HttpBackend for this service to skip http interceptor while
    // loading configuration data on bootstrap, otherwise the interceptor itself cannot get config key
    private httpHandler: HttpBackend,
    private logger: NGXLogger
  ) {
    this.httpClient = new HttpClient(httpHandler);
  }

  // loadApplicationConfiguration() {
  //   this.logger.debug(this.constructor.name + ' loadApplicationConfiguration from file ', this._configLocation);
  //   return this._httpClient.get<AppConfig>(this._configLocation)
  //     .toPromise()
  //     .then((appConfigJsonContent: AppConfig) => {
  //       // this.configuration = {
  //       //   localStorage: data['localStorage'],
  //       //   obchodnik: data['obchodnik']
  //       // };
  //       this.configuration = appConfigJsonContent;
  //     });
  // }

  async loadApplicationConfigurationAsync() {
    this.logger.debug(this.constructor.name + ' loadApplicationConfiguration from file ', this.configLocation);
    const appConfigJsonContent = await this.httpClient.get<AppConfig>(this.configLocation)
      .toPromise();
    this.configuration = appConfigJsonContent;
  }
}
