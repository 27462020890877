import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomFormValidator } from '../classes/custom-form-validator';
import { CollectionBillDto } from 'src/app/models/collection-bill-dto';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { InsuranceContractDto } from 'src/app/models/insurance-contract-dto';
import { ObjectMapperService } from '../services/object-mapper.service';
import { AmountType } from '../classes/amount-type.enum';
import { timeout, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { CollectionBillPostDto } from 'src/app/models/collection-bill-post-dto';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DDMMYYYY', 'DD.MM.YYYY']
  },
  display: { // Formats: https://momentjs.com/docs/#/displaying/
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY', // date picker upper left corner label
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-collection-bill-create',
  templateUrl: './collection-bill-create.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'cs-CS' }
  ]
})
export class CollectionBillCreateComponent implements OnInit {

  @ViewChild('resultCard', { static: true }) resultCardDiv: ElementRef;

  private icsApiUrl: string = this.configService.configuration.api.gatewayBaseUrl
    + this.configService.configuration.api.insuranceContractService.urlPrefix;
  private icsEndpoint: string = this.configService.configuration.api.insuranceContractService.insuranceContracts;
  private icsBills: string = this.configService.configuration.api.insuranceContractService.bills;
  private contractType: string = this.configService.configuration.api.insuranceContractService.contractType;
  private fields: string = this.configService.configuration.api.insuranceContractService.fields;

  /* First radio button group - sets value of form's paymentReason from AmountType enum */
  public readonly INSURANCE_AMOUNT_TYPE = [
    { id: 1, name: 'Pojistné' },
    // { id: 2, name: 'NR 2 INTENTIONALLY SKIPPED - IS IN INSURANCE_AMOUNT_SUBTYPE'},
    { id: 3, name: 'Doplatek' },
    { id: 4, name: 'Jiný důvod' }
  ];

  /* Second radio button group - sets value of form's paymentReason from AmountType enum
   if INSURANCE_AMOUNT_TYPE.id == 1 is selected */
  public readonly INSURANCE_AMOUNT_SUBTYPE = [
    { id: 1, name: '1. pojistné' },
    { id: 2, name: 'následné pojistné' }
  ];

  private allowedMaxAmount = 100000;
  private allowedMinAmount = 0;
  public backendMessage: string;
  public collectionBillForm: FormGroup;
  private collectionBillForPost: CollectionBillPostDto;
  private contract: CollectionBillDto = new CollectionBillDto();
  public createdCollectionBill: CollectionBillDto;
  public createErrorMessage: string;
  private formValueObject: CollectionBillDto;
  public initialContractAmount = 0;
  public isAmountFetchedFromContract = false;
  public isAmountWarningVisible = false;
  public isCreatedCollectionBillCardVisible = false;
  public isCreating = false;
  public isErrorCollectionBillCardVisible = false;
  public isInsuranceContractIdWarningVisible = false;
  public isSearchButtonDisabled = true;
  private isSearchingAllowed$ = new BehaviorSubject(false);
  public maxAllowedLengthAddress = 80;
  public maxAllowedLengthClient = 30;
  public maxAllowedLengthNote = 60;
  public maxAllowedLengthVenue = 20;
  public searchErrorMessage: string;

  constructor(
    private fb: FormBuilder,
    private configService: AppConfigService,
    private http: HttpClient,
    private snackBar: SnackBarService,
    private objectMapper: ObjectMapperService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.collectionBillForm = this.fb.group({
      insuranceContractId: ['', [Validators.required, Validators.pattern('^\\d*$')]],
      personalIdentificationOrCompanyRegNumber: ['', [Validators.required, CustomFormValidator.personalIdCheck()]],
      client: ['', [Validators.required, Validators.maxLength(this.maxAllowedLengthClient)]],
      address: ['', [Validators.required, Validators.maxLength(this.maxAllowedLengthAddress)]],
      amount: [0, [Validators.pattern('^\\d{0,6}(\\.\\d{1,2})?'),
      Validators.min(this.allowedMinAmount),
      Validators.max(this.allowedMaxAmount)]],
      periodFrom: ['', [Validators.required]],
      periodTo: ['', [Validators.required]],
      paymentReason: [AmountType[1], [Validators.required]],
      venue: ['', [Validators.required, Validators.maxLength(this.maxAllowedLengthVenue)]],
      insuranceAmountType: [1], // "Pojistné" selected by default
      insuranceAmountSubtype: [1] // "1.pojistne" selected by default
    },
      { validators: CustomFormValidator.dateCheck() }
    );

    /**
     * Listen for changes done by user in form input fields
     */
    this.onFormValueChanges();

    this.isSearchingAllowed$.subscribe(
      value => {
        this.isSearchButtonDisabled = value;
      }
    );
  }

  /**
   * Submits form data to ICS backend to create new collection bill
   */
  public submitNewCollectionBill() {
    this.logger.debug(this.constructor.name + ': submitting form', this.collectionBillForm);
    this.isCreatedCollectionBillCardVisible = false;
    this.isErrorCollectionBillCardVisible = false;
    this.updateFormControlValueAndValidity();
    if (this.collectionBillForm.valid) {
      this.isCreating = true;
      this.isInsuranceContractIdWarningVisible = false;
      this.formValueObject = { ...this.collectionBillForm.value };
      this.collectionBillForPost = this.objectMapper.mapCollectionBillFormDTOToBackendCollectionBillDTO(this.formValueObject);
      this.createCollectionBill(this.collectionBillForPost);
    } else {
      this.snackBar.addMessage('Zkontrolujte zadaná data, upravte červeně zvýrazněné položky.');
    }
  }

  /**
   * Listen for changes done by user in form input fields. Specific action can be performed on particular
   * form control then as per need.
   */
  private onFormValueChanges(): void {
    this.collectionBillForm.get('insuranceContractId').valueChanges.subscribe(val => {
      if (val && val !== '') {
        this.isSearchButtonDisabled = false;
      } else {
        this.isSearchButtonDisabled = true;
      }
    });

    this.collectionBillForm.get('insuranceAmountType').valueChanges.subscribe(val => {
      // Set paymentReason value from enum accordingly
      if (val >= AmountType.ADJUSTMENT) {
        this.collectionBillForm.get('paymentReason').setValue(AmountType[val]);
      } else if (val === AmountType.FIRST_PREMIUM) {
        this.collectionBillForm.get('paymentReason').setValue(AmountType[this.collectionBillForm.get('insuranceAmountSubtype').value]);
      }

      // if (val == this.INSURANCE_AMOUNT_TYPE.find(t => t.name == 'Jiný důvod').id) {
      // Show/hide additional controls and inputs based on user's payment type selection
      if (val === AmountType.OTHER) {
        this.createFormControlReasonText();
        this.setDateInputsRequired(false);
        this.updateFormControlValueAndValidity();
      } else if (this.collectionBillForm.get('note')) {
        this.removeFormControl('note');
        this.setDateInputsRequired(true);
        this.updateFormControlValueAndValidity();
      }

      this.checkAmountTypeAndValue();
    });

    /**
     * The below observes changes in date input field AND selected amount type OTHER - if this combination is
     * selected by user the date input fields are not mandatory (required validator off), however if the users
     * enters value into the date field, then validations should be run to ensure both the date inputs are filled
     * and with proper values (e.g. periodTo >= periodFrom)
     */
    this.collectionBillForm.get('periodFrom').valueChanges.subscribe(val => {
      if (this.collectionBillForm.get('insuranceAmountType').value === AmountType.OTHER) {
        if (val) {
          // User entered value into date periodFrom, in such case the dates are required in both date input fields
          this.setDateInputsRequired(true);
        } else if (!this.collectionBillForm.get('periodTo').value) {
          // No value in date input periodFrom, check if value is present in periodTo - if not make both date inputs not mandatory
          this.setDateInputsRequired(false);
        } else {
          // No value in date input periodFrom, but value is present in periodTo - keep date input fields as mandatory
        }
      }
    });

    /**
     * The below observes changes in date input field AND selected amount type OTHER - if this combination is
     * selected by user the date input fields are not mandatory (required validator off), however if the users
     * enters value into the date field, then validations should be run to ensure both the date inputs are filled
     * and with proper values (e.g. periodTo >= periodFrom)
     */
    this.collectionBillForm.get('periodTo').valueChanges.subscribe(val => {
      if (this.collectionBillForm.get('insuranceAmountType').value === AmountType.OTHER) {
        if (val) {
          // User entered value into date periodTo, in such case the dates are required in both date input fields
          this.setDateInputsRequired(true);
        } else if (!this.collectionBillForm.get('periodFrom').value) {
          // No value in date input periodTo, check if value is present in periodFrom - if not make both date inputs not mandatory
          this.setDateInputsRequired(false);
        } else {
          // No value in date input periodTo, but value is present in periodFrom - keep date input fields as mandatory
        }
      }
    });

    this.collectionBillForm.get('insuranceAmountSubtype').valueChanges.subscribe(val => {
      // Set paymentReason value from enum accordingly
      this.collectionBillForm.get('paymentReason').setValue(AmountType[val]);
      this.checkAmountTypeAndValue();
    });

    this.collectionBillForm.get('amount').valueChanges.subscribe(() => {
      this.checkAmountTypeAndValue();
    });
  }

  /**
   * Show warning if user overrides amount retrieved from backend by lower value
   * warning is only applicable for amount type FIRST_PREMIUM (AmountType[1], subtype 1), otherwise no check needed
   */
  private checkAmountTypeAndValue(): void {
    if (this.collectionBillForm.get('paymentReason').value === AmountType[1]
      && this.collectionBillForm.get('insuranceAmountSubtype').value === 1) {
      const currentAmount = this.collectionBillForm.get('amount').value;
      this.isAmountWarningVisible = this.isAmountFetchedFromContract && currentAmount !== this.initialContractAmount;
    } else {
      this.isAmountWarningVisible = false;
    }
  }

  private createFormControlReasonText(): void {
    this.collectionBillForm.addControl('note', new FormControl('', [Validators.required, Validators.maxLength(this.maxAllowedLengthNote)]));
    this.collectionBillForm.get('note').markAsTouched();
  }

  private removeFormControl(controlName: string): void {
    this.collectionBillForm.get(controlName).setErrors(null);
    this.collectionBillForm.removeControl(controlName);
  }

  /**
   * Sets form's date input controls to required/false.
   * @param isRequired boolean
   */
  private setDateInputsRequired(isRequired: boolean): void {
    if (isRequired) {
      /**
       * For all payment types EXCEPT "OTHER" validations of entered date values are required:
       *  - date fields are mandatory, dateTo has to be >= dateFrom, both values have to be
       * present.
       */

      // set required validators on date fields
      this.collectionBillForm.get('periodFrom').setValidators([Validators.required]);
      this.collectionBillForm.get('periodTo').setValidators([Validators.required]);
    } else {
      /**
       * For payment type OTHER the situation is - both date fields may be empty, then these are not
       * mandatory.
       * If any value is entered by user, then same validations as in other case is to be done - date
       * values are mandatory (both), dateTo >= dateFrom, both have to be present.
       */
      if (this.collectionBillForm.get('periodFrom').value || this.collectionBillForm.get('periodTo').value) {
        this.logger.debug(this.constructor.name, 'other payment type, values present, required');
        this.collectionBillForm.get('periodFrom').setValidators([Validators.required]);
        this.collectionBillForm.get('periodTo').setValidators([Validators.required]);
      } else {
        this.logger.debug(this.constructor.name, 'other payment type, values empty, not required');
        this.collectionBillForm.get('periodFrom').clearValidators();
        this.collectionBillForm.get('periodTo').clearValidators();
      }
    }
    this.updateFormControlValueAndValidity('periodFrom');
    this.updateFormControlValueAndValidity('periodTo');
  }

  /**
   * Recalculates form's control value and validity if control name is provided, otherwise performs same
   * operation for all form controls.
   * @param controlName (optional) form's control name
   */
  // tslint:disable: forin
  private updateFormControlValueAndValidity(controlName?: string): void {
    if (controlName) {
      this.collectionBillForm.get(controlName).updateValueAndValidity({ onlySelf: true, emitEvent: false });
    } else {
      // Update all controls within the form
      for (const ctrl in this.collectionBillForm.controls) {
        this.logger.debug(this.constructor.name + ': control ' + ctrl + ' value', this.collectionBillForm.get(ctrl).value);
        this.collectionBillForm.get(ctrl).updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }
  }
  // tslint:enable: forin

  /**
   * Clears form control values, sets default values where required
   */
  // tslint:disable: forin
  private clearFormControlValues(shouldClearContractId: boolean): void {
    this.initialContractAmount = 0;
    this.isAmountFetchedFromContract = false;
    for (const ctrl in this.collectionBillForm.controls) {
      switch (ctrl) {
        case 'insuranceContractId':
          if (shouldClearContractId) {
            this.collectionBillForm.get(ctrl).setValue('');
          }
          break;
        case 'amount':
          this.collectionBillForm.get(ctrl).setValue(0);
          break;
        case 'paymentReason':
          this.collectionBillForm.get(ctrl).setValue(1);
          break;
        case 'insuranceAmountType':
          this.collectionBillForm.get(ctrl).setValue(1);
          break;
        case 'insuranceAmountSubtype':
          this.collectionBillForm.get(ctrl).setValue(1);
          break;
        default:
          this.collectionBillForm.get(ctrl).setValue('');
          break;
      }
      this.collectionBillForm.get(ctrl).setErrors(null);
      this.collectionBillForm.get(ctrl).markAsUntouched();
    }
    this.clearUserMessages();
    this.updateFormControlValueAndValidity();
    this.collectionBillForm.setErrors({ invalid: true });
  }
  // tslint:enable: forin

  /**
   * Nulls recent messages shown to users upon form submit
   */
  private clearUserMessages(): void {
    this.searchErrorMessage = null;
    this.createErrorMessage = null;
    this.backendMessage = null;
  }

  /**
   * Calls ICS backend to retrieve particular insurance contract details. If such details are returned
   * these are applied to form values. Otherwise warning is shown to user to enter details manually.
   * @param insuranceContractNumber insurance contract id/number
   */
  public getContractData(insuranceContractNumber: number) {
    this.clearFormControlValues(false);
    this.isInsuranceContractIdWarningVisible = false;
    this.isCreatedCollectionBillCardVisible = false;
    this.isErrorCollectionBillCardVisible = false;

    this.getInsuranceContractData(insuranceContractNumber).subscribe(
      (contract: InsuranceContractDto) => {
        this.contract = this.objectMapper.mapBackendContractDTOToCollectionBillFormDTO(contract);
        this.initialContractAmount = this.contract.amount ? this.contract.amount : 0;
        this.isAmountFetchedFromContract = this.contract.amount ? true : false;
        this.collectionBillForm.patchValue(this.contract);
      },
      (error: HttpErrorResponse) => {
        this.isInsuranceContractIdWarningVisible = true;

        if (error.message === 'Timeout has occurred') {
          this.searchErrorMessage = 'Timeout - zadejte údaje ručně, prosím!';
        } else if (error.name === 'HttpErrorResponse' && error.status === 0) {
          this.searchErrorMessage = 'Chyba komunikace - opakujte hledání později, prosím.';
        } else {
          this.searchErrorMessage = 'Zadejte údaje ručně, prosím!';
        }
        this.snackBar.addMessage('Zadejte údaje ručně, prosím!');
      });
  }

  /**
   * Http call to ICS backend to retrieve data for particular insurance contract.
   * @param contractId insurance contract id/number to search for
   */
  private getInsuranceContractData(contractId: number): Observable<any> {
    return this.http
      .get(this.icsApiUrl + this.icsEndpoint + '/' + contractId + '?' + this.contractType + '&' + this.fields)
      .pipe(
        timeout(this.configService.configuration.http.timeout.default),
        catchError(e => {
          return throwError(e);
        })
      );
  }

  private createCollectionBill(collectionBill: CollectionBillPostDto): void {
    this.clearUserMessages();
    this.postCollectionBillData(collectionBill).subscribe(
      response => {
        this.snackBar.addMessage('Inkasní blok úspěšně vytvořen!');
        this.handleCreateSuccess(response);
      },
      (error: HttpErrorResponse) => {
        this.snackBar.addMessage('Chyba při vytváření inkasního bloku!');
        this.handleCreateError(error);
      }
    );
  }

  private postCollectionBillData(collectionBillData: CollectionBillPostDto): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.logger.debug(this.constructor.name + ' request body data to post', collectionBillData);

    return this.http
      .post<CollectionBillPostDto>(
        this.icsApiUrl + this.icsEndpoint + '/' + collectionBillData.insuranceContractId + this.icsBills + '?' + this.contractType,
        collectionBillData,
        { headers }
      ).pipe(
        timeout(this.configService.configuration.http.timeout.post),
        catchError(e => {
          return throwError(e);
        })
      );
  }

  private handleCreateSuccess(okResponse: CollectionBillDto) {
    this.isErrorCollectionBillCardVisible = false;
    this.isCreatedCollectionBillCardVisible = true;
    this.createdCollectionBill = okResponse;
    this.isCreating = false;
    this.scrollToResultCard();
    this.clearFormControlValues(true);
  }

  private handleCreateError(error: HttpErrorResponse) {
    this.isCreatedCollectionBillCardVisible = false;

    if (error.message === 'Timeout has occurred') {
      this.createErrorMessage = 'Timeout - neobdrželi jsme potvrzení na vytvoření inkasního bloku, zkuste vytvořit inkasní blok později, prosím.';
    } else if (error.name === 'HttpErrorResponse' && error.status === 0) {
      this.searchErrorMessage = 'Chyba komunikace - zkuste vytvořit inkasní blok později, prosím.';
    } else {
      this.createErrorMessage = 'Nepodařilo se vygenerovat dokument. Zkontrolujte zadané údaje a opakujte pokus později.';
      this.backendMessage = error.error.message ? error.error.message : null;
    }

    this.isErrorCollectionBillCardVisible = true;
    this.isCreating = false;
    this.scrollToResultCard();
  }

  public openCollectionBillLink(urlString: any): void {
    window.open(urlString, '_blank');
  }

  private scrollToResultCard(): void {
    this.resultCardDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

}
