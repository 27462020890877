import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CollectionBillDto } from '../../../models/collection-bill-dto';
import { AppConfigService } from 'src/app/services/app-config.service';
import { timeout, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-collection-bill-search',
  templateUrl: './collection-bill-search.component.html'
})
export class CollectionBillSearchComponent implements OnInit {

  @Input() receivedSearchString: string;
  @Output() searchSuccessMessage = new EventEmitter<string>();
  @Output() searchErrorMessage = new EventEmitter<string>();

  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort, { static: true }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  private icsApiUrl = this.configService.configuration.api.gatewayBaseUrl
    + this.configService.configuration.api.insuranceContractService.urlPrefix;
  private icsEndpoint = this.configService.configuration.api.insuranceContractService.insuranceContracts;
  private icsBills = this.configService.configuration.api.insuranceContractService.bills;
  private contractType = this.configService.configuration.api.insuranceContractService.contractType;

  private collectionBills: CollectionBillDto[] = [];
  public dataSource = new MatTableDataSource(this.collectionBills);
  public readonly DISPLAYED_COLUMNS: string[] = [
    'collectionBillId',
    'insuranceContractId',
    'client',
    'amount',
    'documentUrl'
  ];

  constructor(
    private configService: AppConfigService,
    private http: HttpClient,
    private snackBar: SnackBarService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.setDataSourceAttributes();
  }

  public startSearch(insuranceContractNumber: number) {
    this.collectionBills = [];
    this.searchErrorMessage.emit(null);

    this.getInsuranceContractsTableData(insuranceContractNumber).subscribe(
      (collectionBills: CollectionBillDto[]) => {
        this.collectionBills = collectionBills;
        this.dataSource = new MatTableDataSource(this.collectionBills);
        this.setDataSourceAttributes();
      },
      (error: HttpErrorResponse) => {
        this.handleRequestError(error);
      }
    );
    this.searchSuccessMessage.emit(null);
  }

  private setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private getInsuranceContractsTableData(contractId: number): Observable<any> {
    this.logger.debug(
      this.constructor.name
      + 'using url '
      + this.icsApiUrl
      + this.icsEndpoint
      + '/'
      + contractId
      + this.icsBills
      + '?'
      + this.contractType);

    return this.http
      .get(this.icsApiUrl + this.icsEndpoint + '/' + contractId + this.icsBills + '?' + this.contractType)
      .pipe(
        timeout(this.configService.configuration.http.timeout.default),
        catchError(e => {
          return throwError(e);
        })
      );
  }

  public openCollectionBillLink(collectionBill: CollectionBillDto): void {
    this.snackBar.addMessage(`Stahuji dokument ${collectionBill.collectionBillId}, vyčkejte, prosím...`);
    this.getCollectionBillDocumentUrl(collectionBill.insuranceContractId, collectionBill.collectionBillId)
      .then(
        (response) => {
          this.logger.debug(this.constructor.name + ' got link', response.documentUrl.toString(), response);
          window.open(response.documentUrl.toString(), '_blank');
        })
      .catch(
        (error) => {
          this.logger.debug(this.constructor.name + ' error getting link', error);
          this.snackBar.addMessage(`Nepodařilo se získat link pro stažení inkasního bloku ${collectionBill.collectionBillId}.`);
          this.handleRequestError(error);
        });
  }

  private getCollectionBillDocumentUrl(contractId: string, billId: string): Promise<CollectionBillDto> {
    this.logger.debug(
      this.constructor.name
      + 'using url '
      + this.icsApiUrl
      + this.icsEndpoint
      + '/'
      + contractId
      + this.icsBills
      + '/'
      + billId
      + '?'
      + this.contractType);

    return this.http
      .get<CollectionBillDto>(
        this.icsApiUrl + this.icsEndpoint + '/' + contractId + this.icsBills + '/' + billId + '?' + this.contractType
      )
      .toPromise();
  }

  private handleRequestError(error: HttpErrorResponse) {
    if (error.message === 'Timeout has occurred') {
      this.searchErrorMessage.emit('Timeout - neobdrželi jsme odpověď včas, opakujte hledání později, prosím.');
    } else if (error.name === 'HttpErrorResponse' && error.status === 0) {
      this.searchErrorMessage.emit('Chyba komunikace - opakujte hledání později, prosím.');
    } else if (error.name === 'HttpErrorResponse' && error.status === 403) {
      this.searchErrorMessage.emit('Chyba oprávnění - nelze vyhledat.');
    } else {
      this.searchErrorMessage.emit('Pro zadané číslo nebylo nic nalezeno. Zkontrolujte zadané údaje a opakujte hledání později.');
    }
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
