import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
// import { FormsModule } from '@angular/forms';

// application imports - static
import { environment } from 'src/environments/environment';

// application imports - modules
import { MaterialModule } from './material.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CollectionBillRoutingModule } from './modules/collection-bill/collection-bill-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { CollectionBillModule } from './modules/collection-bill/collection-bill.module';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

// application imports - services
import { AppConfigService } from './services/app-config.service';
import { HttpOutInterceptorService } from './services/http-out-interceptor.service';
import { CookieService } from 'ngx-cookie-service';

// application imports - components
import { AppComponent } from './app.component';
import { AuthComponent } from './modules/auth/auth.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { UnauthComponent } from './modules/unauth/unauth.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './configuration/custom-paginator-configuration';
import { HttpErrInterceptorService } from './services/http-err-interceptor.service';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadApplicationConfigurationAsync();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LogoutComponent,
    UnauthComponent,
    NavigationComponent
  ],
  imports: [
    // Mind the order of module imports, especially for routing!
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF }),
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    HttpClientModule,
    NgProgressModule.withConfig({
      spinner: false,
      color: '#efb610',
      thick: true
    }),
    NgProgressHttpModule,
    CollectionBillModule,
    CollectionBillRoutingModule,
    AppRoutingModule
  ],
  exports: [
    MaterialModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    CookieService,
    HttpOutInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpOutInterceptorService,
      multi: true
    },
    HttpErrInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrInterceptorService,
      multi: true
    },
    {
      provide: MatPaginatorIntl, useValue: CustomPaginator()
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
