import { MatPaginatorIntl } from '@angular/material';

export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Počet řádků:';
    customPaginatorIntl.nextPageLabel = 'Další stránka';
    customPaginatorIntl.previousPageLabel = 'Předchozí stránka';
    customPaginatorIntl.firstPageLabel = 'První stránka';
    customPaginatorIntl.lastPageLabel = 'Poslední stránka';
    customPaginatorIntl.getRangeLabel = getModifiedRangeLabel.bind(this);

    function getModifiedRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return `0 z ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} z ${length}`;
    }

    return customPaginatorIntl;
}
