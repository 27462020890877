import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { UnauthComponent } from './modules/unauth/unauth.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'unauthorized', component: UnauthComponent },
  { path: 'logout', component: LogoutComponent },
  // { path: 'inkasni-blok', component: CollectionBillComponent},
  // { path: '', redirectTo: 'inkasni-blok', pathMatch: 'full'},
  // { path: '', component: CollectionBillComponent},
  // { path: '', component: CollectionBillHomeComponent, canActivate: [AuthGuard] },

  // 404
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  // reload - force reinit of component (do not reuse existing) when redirecting via router
  // imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

  // constructor(private router: Router) {
  //   // override the route reuse strategy - force reinit of component (do not reuse existing) when redirecting via router
  //   this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //     return false;
  //   };
  // }

}
