import { Injectable, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AppConfigService } from './app-config.service';
import { JwtContent } from '../models/jwt-content';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() isLocalStorageReady = new EventEmitter<boolean>();

  private isUserAuthorized$ = new BehaviorSubject(false);
  private lsJwtKeyName: string = this.configService.configuration.localStorage.jwtKeyName;
  private OBCHODNIK_COOKIE_NAME: string = this.configService.configuration.obchodnik.cookie.name;

  private icsApiUrlBase: string = this.configService.configuration.api.gatewayBaseUrl;
  private icsApiUrlPrefix: string = this.configService.configuration.api.insuranceContractService.urlPrefix;
  private icsApiUrlEndpoint: string = this.configService.configuration.api.insuranceContractService.userDetails;

  constructor(
    private configService: AppConfigService,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
    private logger: NGXLogger
  ) { }

  /**
   * Calls ICS backend to validate user's token
   * @param token JWT generated by obchodnik application
   */
  public async tryAuthenticateUser(token: string): Promise<void> {
    this.logger.debug(this.constructor.name + ' isAuth? >', this.getIsUserAuthorized());

    // seve token into local storage that it can be put into http request header by interceptor
    this.setLocalStorageValue(this.lsJwtKeyName, token);

    // call async-await backend /v1/userDetails with token to validate it, response to promise
    await this.validateUserInfo()
      .then(
        (response) => {
          this.setIsUserAuthorized(true);
          this.setLocalStorageValues(response);
          this.router.navigate(['/search']);
          this.logger.debug(this.constructor.name + ' authenticate user - SUCCESS', response);
        })
      .catch(
        (error) => {
          this.setIsUserAuthorized(false);
          this.removeLocalStorageValue(this.lsJwtKeyName);
          this.router.navigate(['/unauthorized']);
          this.logger.debug(this.constructor.name + ' authenticate user - ERROR', error);
        });

    if (this.cookieService.check(this.OBCHODNIK_COOKIE_NAME)) {
      this.cookieService.delete(this.OBCHODNIK_COOKIE_NAME);
    }
  }

  /**
   * Http call to ICS backend to validate current user's token present in http headers.
   */
  private validateUserInfo(): Promise<JwtContent> {
    return this.http
      .get<JwtContent>(this.icsApiUrlBase + this.icsApiUrlPrefix + this.icsApiUrlEndpoint)
      .toPromise();
  }

  public getLocalStorageValue(key: string): string {
    this.logger.debug(this.constructor.name + ' getLocalStorageValue', key, localStorage.getItem(key));
    return localStorage.getItem(key);
  }

  private setLocalStorageValues(valueObject: JwtContent): void {
    Object.entries(valueObject)
      .map(([key, value]) => this.setLocalStorageValue(key, value));
    this.isLocalStorageReady.emit(true);
  }

  public setLocalStorageValue(key: string, value: string) {
    this.logger.debug(this.constructor.name + ' setLocalStorageValue', key, value);
    localStorage.setItem(key, value);
  }

  public removeLocalStorageValue(key: string) {
    this.logger.debug(this.constructor.name + ' removeLocalStorageValue', key);
    localStorage.removeItem(key);
  }

  public getIsUserAuthorized(): boolean {
    this.logger.debug(this.constructor.name + ' getIsUserAuthorized', this.isUserAuthorized$.value);
    return this.isUserAuthorized$.value;
  }

  public setIsUserAuthorized(value: boolean) {
    this.logger.debug(this.constructor.name + ' setIsUserAuthorized', value);
    this.isUserAuthorized$.next(value);
  }

}
