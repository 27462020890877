/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./unauth.component";
import * as i6 from "ngx-cookie-service";
import * as i7 from "../../services/auth.service";
import * as i8 from "../../services/app-config.service";
import * as i9 from "@angular/router";
import * as i10 from "ngx-logger";
var styles_UnauthComponent = [];
var RenderType_UnauthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnauthComponent, data: {} });
export { RenderType_UnauthComponent as RenderType_UnauthComponent };
export function View_UnauthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "message-wrapper-padded message-wrapper-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Z bezpe\u010Dnostn\u00EDch d\u016Fvod\u016F do\u0161lo k odhl\u00E1\u0161en\u00ED. P\u0159ihla\u0161te se, pros\u00EDm, znovu p\u0159es aplikaci Obchodn\u00EDk pomoc\u00ED tla\u010D\u00EDtka n\u00ED\u017Ee."])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "button-wrapper-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "single-button"], ["color", "primary"], ["mat-flat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(5, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Obchodn\u00EDk"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 5).disabled || null); var currVal_1 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_UnauthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-unauth", [], null, null, null, View_UnauthComponent_0, RenderType_UnauthComponent)), i0.ɵdid(1, 49152, null, 0, i5.UnauthComponent, [i6.CookieService, i7.AuthService, i8.AppConfigService, i9.Router, i10.NGXLogger], null, null)], null, null); }
var UnauthComponentNgFactory = i0.ɵccf("app-unauth", i5.UnauthComponent, View_UnauthComponent_Host_0, {}, {}, []);
export { UnauthComponentNgFactory as UnauthComponentNgFactory };
