import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Directive({
  // tslint:disable: directive-selector
  selector: '[digitsWithSeparator]'
  // tslint:enable: directive-selector
})
export class DigitsWithSeparatorDirective {
  private separatorCharCounter = 0;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  // tslint:disable: whitespace
  @Input() requireSeparator?= true;
  @Input() separatorChar?= '/';
  @Input() separatorAllowedCount?= 1;
  // tslint:enable: whitespace

  inputElement: HTMLInputElement;

  constructor(
    public el: ElementRef,
    private logger: NGXLogger) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) || // Allow: Cmd+X (Mac)
      (this.requireSeparator && (e.key === this.separatorChar) && this.separatorCharCounter < this.separatorAllowedCount) // Allow: defined number of separators
    ) {
      // let it happen, don't do anything
      return;
    } else if (e.key === this.separatorChar &&
      this.separatorCharCounter >= this.separatorAllowedCount) {
      /**
       * Check how many separators the user already typed and only allow
       * entering these up to this.separatorAllowedCount value
       */
      this.logger.debug(`separatorAllowedCount reached: [${this.separatorCharCounter}]`);
      e.preventDefault();
    }

    // Ensure that it is a number or separator char otherwise stop the keypress
    if (e.key === ' ' || (e.key !== this.separatorChar && isNaN(Number(e.key)))) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if (!this.requireSeparator) {
      return;
    } else {
      // count number of occurrences of separatorChar in value
      this.separatorCharCounter = this.el.nativeElement.value.split(this.separatorChar).length - 1;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData('text/plain');
    this.pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData('text');
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this.inputElement;
      this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
    }
  }

  private sanitizeInput(input: string): string {
    let result = input;

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) { // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }
    return result;
  }

}

// https://github.com/changhuixu/ngx-digit-only
