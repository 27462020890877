import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "ngx-logger";
export class AuthService {
    constructor(configService, cookieService, http, router, logger) {
        this.configService = configService;
        this.cookieService = cookieService;
        this.http = http;
        this.router = router;
        this.logger = logger;
        this.isLocalStorageReady = new EventEmitter();
        this.isUserAuthorized$ = new BehaviorSubject(false);
        this.lsJwtKeyName = this.configService.configuration.localStorage.jwtKeyName;
        this.OBCHODNIK_COOKIE_NAME = this.configService.configuration.obchodnik.cookie.name;
        this.icsApiUrlBase = this.configService.configuration.api.gatewayBaseUrl;
        this.icsApiUrlPrefix = this.configService.configuration.api.insuranceContractService.urlPrefix;
        this.icsApiUrlEndpoint = this.configService.configuration.api.insuranceContractService.userDetails;
    }
    /**
     * Calls ICS backend to validate user's token
     * @param token JWT generated by obchodnik application
     */
    tryAuthenticateUser(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.logger.debug(this.constructor.name + ' isAuth? >', this.getIsUserAuthorized());
            // seve token into local storage that it can be put into http request header by interceptor
            this.setLocalStorageValue(this.lsJwtKeyName, token);
            // call async-await backend /v1/userDetails with token to validate it, response to promise
            yield this.validateUserInfo()
                .then((response) => {
                this.setIsUserAuthorized(true);
                this.setLocalStorageValues(response);
                this.router.navigate(['/search']);
                this.logger.debug(this.constructor.name + ' authenticate user - SUCCESS', response);
            })
                .catch((error) => {
                this.setIsUserAuthorized(false);
                this.removeLocalStorageValue(this.lsJwtKeyName);
                this.router.navigate(['/unauthorized']);
                this.logger.debug(this.constructor.name + ' authenticate user - ERROR', error);
            });
            if (this.cookieService.check(this.OBCHODNIK_COOKIE_NAME)) {
                this.cookieService.delete(this.OBCHODNIK_COOKIE_NAME);
            }
        });
    }
    /**
     * Http call to ICS backend to validate current user's token present in http headers.
     */
    validateUserInfo() {
        return this.http
            .get(this.icsApiUrlBase + this.icsApiUrlPrefix + this.icsApiUrlEndpoint)
            .toPromise();
    }
    getLocalStorageValue(key) {
        this.logger.debug(this.constructor.name + ' getLocalStorageValue', key, localStorage.getItem(key));
        return localStorage.getItem(key);
    }
    setLocalStorageValues(valueObject) {
        Object.entries(valueObject)
            .map(([key, value]) => this.setLocalStorageValue(key, value));
        this.isLocalStorageReady.emit(true);
    }
    setLocalStorageValue(key, value) {
        this.logger.debug(this.constructor.name + ' setLocalStorageValue', key, value);
        localStorage.setItem(key, value);
    }
    removeLocalStorageValue(key) {
        this.logger.debug(this.constructor.name + ' removeLocalStorageValue', key);
        localStorage.removeItem(key);
    }
    getIsUserAuthorized() {
        this.logger.debug(this.constructor.name + ' getIsUserAuthorized', this.isUserAuthorized$.value);
        return this.isUserAuthorized$.value;
    }
    setIsUserAuthorized(value) {
        this.logger.debug(this.constructor.name + ' setIsUserAuthorized', value);
        this.isUserAuthorized$.next(value);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.NGXLogger)); }, token: AuthService, providedIn: "root" });
