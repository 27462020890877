import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrInterceptorService {

  constructor(
    private router: Router,
    private logger: NGXLogger
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(
          event => this.handleResponse(req, event),
          error => this.handleError(req, error)
        )
      );
  }

  private handleResponse(req: HttpRequest<any>, event) {
    this.logger.debug(this.constructor.name + ' incoming request - ok', req, event);
    // TODO: Is any common response handling required?
    // Let incoming Ok response untouched, keep it flowing through the application...
    // console.log('http err interceptor - handleResponse', req);
    // console.log('http err interceptor - handleResponse', event);
  }

  private handleError(req: HttpRequest<any>, error) {
    // TODO: Handle major error events accordingly - e.g. in case of 401/403 redirect to logout?
    if (error.status === 401) {
      this.logger.debug(this.constructor.name + ' incoming request - 401', req, error);
      this.router.navigate(['/unauthorized']);
    }
    // console.log('http err interceptor - handleError', req);
    // console.log('http err interceptor - handleError', error);
  }

}
