<div class="collection-bill-table-wrapper">
    <!-- <h3>IBL search component</h3> -->

    <table mat-table [dataSource]="dataSource" class="collection-bill-result-table mat-elevation-z1" matSort>
        <ng-container matColumnDef="collectionBillId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Číslo bloku</th>
            <td mat-cell *matCellDef="let collectionBill"> {{ collectionBill.collectionBillId }} </td>
        </ng-container>
        <ng-container matColumnDef="insuranceContractId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Číslo PS</th>
            <td mat-cell *matCellDef="let collectionBill"> {{ collectionBill.insuranceContractId }} </td>
        </ng-container>
        <ng-container matColumnDef="client">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Jméno, příjmení / Firma</th>
            <td mat-cell *matCellDef="let collectionBill"> {{ collectionBill.client }} </td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Částka</th>
            <td mat-cell *matCellDef="let collectionBill"> {{ collectionBill.amount }} </td>
        </ng-container>
        <ng-container matColumnDef="documentUrl">
            <th mat-header-cell *matHeaderCellDef>Otevřít</th>
            <td mat-cell *matCellDef="let collectionBill">
                <mat-icon aria-label="download" style="padding-right: 10px; cursor: pointer;"
                    (click)="openCollectionBillLink(collectionBill)">open_in_new</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></tr>
        <tr mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS;"></tr>
    </table>
    <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
        class="collection-bill-result-table-paginator mat-elevation-z1">
    </mat-paginator>

</div>