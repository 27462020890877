import { Url } from 'url';

export class CollectionBillDto {
  collectionBillId: string;
  insuranceContractId: string;
  personalIdentificationOrCompanyRegNumber: string;
  client: string;
  address: string;
  amount: number;
  periodFrom: Date;
  periodTo: Date;
  paymentReason: string;
  venue: string;
  note: string;
  pz: string;
  ppz: string;
  documentUrl: Url;
}
