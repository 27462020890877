import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: NGXLogger
  ) { }

  canActivate(): boolean {
    this.logger.debug(this.constructor.name + ' starting canActivate>');
    if (this.authService.getIsUserAuthorized()) {
      this.logger.debug(this.constructor.name + ' session considered valid');
      return true;
    }
    this.logger.debug(this.constructor.name + ' session considered invalid');
    this.router.navigate(['/auth']);
    return false;
  }
}
