<div class="ibl-body">

  <div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="1em">

    <h2 fxFlexAlign="start" class="page-title">Založení inkasního bloku</h2>

    <form fxFlexAlign="start" [formGroup]="collectionBillForm" (ngSubmit)="submitNewCollectionBill()">

      <div fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="start" fxLayoutGap="1em">
        <div>
          <mat-form-field class="form-row-width">
            <input matInput placeholder="Číslo pojistné smlouvy" type="text" formControlName="insuranceContractId"
              [maxLength]="10">
            <mat-hint align="start">Vkládejte pouze číselnou hodnotu</mat-hint>
            <mat-error
              *ngIf="collectionBillForm.controls.insuranceContractId?.errors && (collectionBillForm.controls.insuranceContractId?.touched || collectionBillForm.controls.insuranceContractId?.dirty)">
              <div>
                <strong>Vkládejte pouze číselnou hodnotu</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <button mat-flat-button color="primary"
            (click)="getContractData(collectionBillForm.controls.insuranceContractId.value)" type="button"
            [disabled]="isSearchButtonDisabled || collectionBillForm.controls.insuranceContractId?.errors">Pokusit se
            doplnit údaje</button>
        </div>

        <mat-card *ngIf="isInsuranceContractIdWarningVisible" class="card-content-warning">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
            <mat-icon aria-label="Manual entry warning" color="primary">pan_tool</mat-icon>
            <span>{{ searchErrorMessage }}</span>
          </div>
        </mat-card>
      </div>

      <div>
        <mat-form-field class="form-row-width">
          <input matInput placeholder="Jméno a příjmení / firma" type="text" formControlName="client">
          <mat-hint *ngIf="collectionBillForm.controls.client.value.length <= maxAllowedLengthClient" align="end">
            {{ collectionBillForm.controls.client.value.length }} / {{ maxAllowedLengthClient }}</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.client?.errors && (collectionBillForm.controls.client?.touched || collectionBillForm.controls.client?.dirty)">
            <div>
              <strong>Jméno a příjmení / firma - max {{ maxAllowedLengthClient }} znaků</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="form-row-width">
          <input digitsWithSeparator requireSeparator="false" separatorChar="/" separatorAllowedCount="1" maxLength="11"
            matInput placeholder="Rodné číslo / IČO" type="text"
            formControlName="personalIdentificationOrCompanyRegNumber">
          <mat-error
            *ngIf="collectionBillForm.controls.personalIdentificationOrCompanyRegNumber?.errors && (collectionBillForm.controls.personalIdentificationOrCompanyRegNumber?.touched || collectionBillForm.controls.personalIdentificationOrCompanyRegNumber?.dirty)">
            <div>
              <strong>Rodné číslo / IČO</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="form-row-width">
          <input matInput placeholder="Adresa" type="text" formControlName="address">
          <mat-hint *ngIf="collectionBillForm.controls.address.value.length <= maxAllowedLengthAddress" align="end">
            {{ collectionBillForm.controls.address.value.length }} / {{ maxAllowedLengthAddress }}</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.address?.errors && (collectionBillForm.controls.address?.touched || collectionBillForm.controls.address?.dirty)">
            <div>
              <strong>Adresa - max {{ maxAllowedLengthAddress }} znaků</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="start">
        <mat-radio-group formControlName="insuranceAmountType" fxLayoutGap="2em">
          <mat-radio-button [value]="amtType.id" color="primary"
            *ngFor="let amtType of INSURANCE_AMOUNT_TYPE; let i = index">
            <div class="radio-button-text">{{ amtType.name }}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="start"
        *ngIf="collectionBillForm.controls.insuranceAmountType.value == 1">
        <mat-radio-group formControlName="insuranceAmountSubtype" fxLayoutGap="2em">
          <mat-radio-button [value]="amtSubtype.id" color="primary"
            *ngFor="let amtSubtype of INSURANCE_AMOUNT_SUBTYPE; let i = index">
            <div class="radio-button-text">{{ amtSubtype.name }}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="collectionBillForm.controls.note" class="fc-row bottom-padding">
        <mat-form-field class="form-row-width">
          <input matInput placeholder="Uveďte účel platby" type="text" formControlName="note">
          <mat-hint *ngIf="collectionBillForm.controls.note.value.length <= maxAllowedLengthNote" align="end">
            {{ collectionBillForm.controls.note.value.length }} / {{ maxAllowedLengthNote }}</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.note?.errors && (collectionBillForm.controls.note?.touched || collectionBillForm.controls.note?.dirty)">
            <div>
              <strong>Uveďte účel platby - max {{ maxAllowedLengthNote }} znaků</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxFlexAlign="start" fxLayoutGap="1em" style="padding-top: 1em;">
        <mat-form-field class="form-row-width">
          <input currencyMask
            [options]="{align: 'left', prefix: '', thousands: ' ', precision: 0, allowNegative: false}" matInput
            placeholder="Částka Kč" type="text" formControlName="amount">
          <mat-error
            *ngIf="collectionBillForm.controls.amount?.errors && (collectionBillForm.controls.amount?.touched || collectionBillForm.controls.amount?.dirty)">
            <div>
              <strong>Částka Kč - max 100 000,-</strong>
            </div>
          </mat-error>
        </mat-form-field>
        <div fxFlexAlign="start">
          <mat-card *ngIf="isAmountWarningVisible" class="card-content-warning">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
              <mat-icon aria-label="Amount warning" color="primary">pan_tool</mat-icon>
              <span>Zadaná částka neodpovídá očekávané splátce - {{ initialContractAmount | thousandSeparator }}
                Kč!</span>
            </div>
          </mat-card>
        </div>
      </div>

      <div>
        <mat-form-field class="form-row-width">
          <input matInput dateInput requireDateSeparator="true" dateSeparatorChar="." maxLength="10"
            [max]="collectionBillForm.controls.periodTo.value" placeholder="Období od" [matDatepicker]="datePickerFrom"
            formControlName="periodFrom" />
          <mat-datepicker-toggle [for]="datePickerFrom" matSuffix tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #datePickerFrom></mat-datepicker>
          <mat-hint align="start">DD.MM.RRRR</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.periodFrom?.errors && (collectionBillForm.controls.periodFrom?.touched || collectionBillForm.controls.periodFrom?.dirty)">
            <div>
              <strong>DD.MM.RRRR</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="form-row-width">
          <input matInput dateInput requireDateSeparator="true" dateSeparatorChar="." maxLength="10"
            [min]="collectionBillForm.controls.periodFrom.value" placeholder="Období do" [matDatepicker]="datePickerTo"
            formControlName="periodTo" />
          <mat-datepicker-toggle [for]="datePickerTo" matSuffix tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #datePickerTo></mat-datepicker>
          <mat-hint align="start">DD.MM.RRRR</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.periodTo?.errors && (collectionBillForm.controls.periodTo?.touched || collectionBillForm.controls.periodTo?.dirty)">
            <div>
              <strong>DD.MM.RRRR</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxFlexAlign="start" fxLayoutGap="1em"
        style="width: calc(100vw - 300px)">
        <mat-form-field class="form-row-width">
          <input matInput placeholder="Vystaveno v ..." type="text" formControlName="venue">
          <mat-hint align="start">Vyplňte místo sjednání - např. &quot;<strong>Praze</strong>&quot;</mat-hint>
          <mat-hint *ngIf="collectionBillForm.controls.venue.value.length <= maxAllowedLengthVenue" align="end">
            {{ collectionBillForm.controls.venue.value.length }} / {{ maxAllowedLengthVenue }}</mat-hint>
          <mat-error
            *ngIf="collectionBillForm.controls.venue?.errors && (collectionBillForm.controls.venue?.touched || collectionBillForm.controls.venue?.dirty)">
            <div>
              <strong>Vyplňte místo sjednání - např. &quot;Praze&quot; - max {{ maxAllowedLengthVenue }} znaků</strong>
            </div>
          </mat-error>
        </mat-form-field>

      </div>

      <div style="height: 2em;">&nbsp;</div>

      <!-- FORM FOOTER -->
      <div fxLayout="column" fxLayoutGap="2em" style="width: calc(100vw - 300px);">

        <!-- SUBMIT BUTTONS -->
        <div fxFlex fxLayout="row" fxFlexAlign="start" fxLayoutAlign="start center">
          <button fxFlexAlign="start center" mat-flat-button type="button" color="primary"
            [disabled]="!collectionBillForm.valid || isCreating || isAmountWarningVisible" (click)="submitNewCollectionBill()">Vytvořit
            inkasní blok</button>
        </div>

        <!-- SUCCESS CARD -->
        <div class="form-row-width" fxFlexAlign="start">
          <mat-card *ngIf="isCreatedCollectionBillCardVisible" fxFlexAlign="end" class="card-content-success">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2em">
              <mat-icon aria-label="success" style="color: lightseagreen;">done</mat-icon>
              <div>
                Inkasní blok <code>{{ createdCollectionBill.collectionBillId }}</code> úspěšně vytvořen!
              </div>
              <div>
                <button mat-raised-button type="button"
                  (click)="openCollectionBillLink(createdCollectionBill.documentUrl)">
                  <mat-icon style="padding-right: 5px;">open_in_new</mat-icon>Stáhnout
                </button>
              </div>
            </div>
          </mat-card>

          <!-- ERROR CARD -->
          <mat-card *ngIf="isErrorCollectionBillCardVisible" class="card-content-error" fxFlexAlign="start">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
              <mat-icon aria-label="error" style="color: #FFE4E1;">error</mat-icon>
              <div>
                {{ createErrorMessage }}
                <br>
                {{ backendMessage }}
              </div>
            </div>
          </mat-card>
        </div>

        <!-- scrollTo placeholder -->
        <div id="resultCard" #resultCard style="visibility: hidden;"></div>

      </div>

    </form>

  </div>

</div>
