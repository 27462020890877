import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html'
})
export class UnauthComponent {

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private configService: AppConfigService,
    private router: Router,
    private logger: NGXLogger
  ) { }

  private emptyLocalStorageOnLogout() {
    this.logger.debug(this.constructor.name + ' emptying localStorage');
    this.authService.setIsUserAuthorized(false);
    localStorage.clear();
    if (this.cookieService.check(this.configService.configuration.obchodnik.cookie.name)) {
      this.cookieService.delete(this.configService.configuration.obchodnik.cookie.name);
    }
  }

  public logout() {
    this.router.navigate(['/logout']);
  }
}
