import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material';
import { SnackBarMessage } from '../models/snack-bar-message';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private messageQueue = [];
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private isSnackBarVisible = false;

  constructor(private snackBar: MatSnackBar) { }

  /**
   * Adds message to queue to be shown in snack bar to user
   * Basic usage:
   *  - import SnackBarService
   *  - inject service in constructor: private snackBar: SnackBarService
   *  - call service in your code: this.snackBar.addMessage('Message for user...');
   *
   * By default the message appears for 5 seconds.
   *
   * @param message Message to display to user in snack bar
   * @param action tbd - action description
   * @param config tbd - config description
   */
  public addMessage(message: string, action?: string, config?: MatSnackBarConfig): void {
    if (!config) {
      config = new MatSnackBarConfig();
      config.duration = 2500;
    }

    const snackBarMessage = new SnackBarMessage();
    snackBarMessage.message = message;
    snackBarMessage.config = config;

    this.messageQueue.push(snackBarMessage);
    if (!this.isSnackBarVisible) {
      this.showMessage();
    }
  }

  private showMessage() {
    if (this.messageQueue.length === 0) {
      return;
    }

    const snackBarMessage = this.messageQueue.shift();
    this.isSnackBarVisible = true;
    setTimeout(() => this.showSnackBar(snackBarMessage));
  }

  private showSnackBar(snackBarMessage: SnackBarMessage) {
    this.snackBarRef = this.snackBar.open(snackBarMessage.message, snackBarMessage.action, snackBarMessage.config);
    this.snackBarRef.afterDismissed().subscribe(
      () => {
        this.isSnackBarVisible = false;
        this.showMessage();
      }
    );
  }

}
