/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logout.component";
import * as i2 from "ngx-cookie-service";
import * as i3 from "../../services/auth.service";
import * as i4 from "../../services/app-config.service";
import * as i5 from "ngx-logger";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "message-wrapper-padded message-wrapper-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Prob\u00EDh\u00E1 p\u0159esm\u011Brov\u00E1n\u00ED..."]))], null, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoutComponent, [i2.CookieService, i3.AuthService, i4.AppConfigService, i5.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("app-logout", i1.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
