/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i3 from "@ngx-progressbar/core";
import * as i4 from "./navigation/navigation.component.ngfactory";
import * as i5 from "./navigation/navigation.component";
import * as i6 from "@angular/cdk/layout";
import * as i7 from "./services/auth.service";
import * as i8 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-progress", [["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i2.View_NgProgressComponent_0, i2.RenderType_NgProgressComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgProgressComponent, [i3.NgProgress], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-navigation", [], null, null, null, i4.View_NavigationComponent_0, i4.RenderType_NavigationComponent)), i1.ɵdid(3, 245760, null, 0, i5.NavigationComponent, [i6.BreakpointObserver, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).spinnerPosition; var currVal_1 = i1.ɵnov(_v, 1).direction; var currVal_2 = i1.ɵnov(_v, 1).thick; var currVal_3 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i8.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, { sidenavToggle: "sidenavToggle" }, []);
export { AppComponentNgFactory as AppComponentNgFactory };
