import { tap } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-logger";
export class HttpErrInterceptorService {
    constructor(router, logger) {
        this.router = router;
        this.logger = logger;
    }
    intercept(req, next) {
        return next.handle(req)
            .pipe(tap(event => this.handleResponse(req, event), error => this.handleError(req, error)));
    }
    handleResponse(req, event) {
        this.logger.debug(this.constructor.name + ' incoming request - ok', req, event);
        // TODO: Is any common response handling required?
        // Let incoming Ok response untouched, keep it flowing through the application...
        // console.log('http err interceptor - handleResponse', req);
        // console.log('http err interceptor - handleResponse', event);
    }
    handleError(req, error) {
        // TODO: Handle major error events accordingly - e.g. in case of 401/403 redirect to logout?
        if (error.status === 401) {
            this.logger.debug(this.constructor.name + ' incoming request - 401', req, error);
            this.router.navigate(['/unauthorized']);
        }
        // console.log('http err interceptor - handleError', req);
        // console.log('http err interceptor - handleError', error);
    }
}
HttpErrInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrInterceptorService_Factory() { return new HttpErrInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.NGXLogger)); }, token: HttpErrInterceptorService, providedIn: "root" });
