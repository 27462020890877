import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})

export class HttpOutInterceptorService implements HttpInterceptor {
  // TODO: Implementation needed - what all functionality we required from outgoing http interceptor?

  constructor(
    private authService: AuthService,
    private configService: AppConfigService,
    private logger: NGXLogger
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.debug(this.constructor.name + ' about to intercept outgoing request...');
    const jwt = this.authService.getLocalStorageValue(this.configService.configuration.localStorage.jwtKeyName);

    if (jwt) {
      const cloned = req.clone({
        // headers: req.headers.set("Authorization", "Bearer " + jwt)
        setHeaders: {
          Authorization: 'Bearer ' + jwt,
          'Access-Control-Allow-Origin': '*'
        }
      });

      this.logger.debug(this.constructor.name + ' outgoing request - added header', cloned);
      return next.handle(cloned);
    } else {
      this.logger.debug(this.constructor.name + ' outgoing request untouched - no token added');
      return next.handle(req);
    }
  }
}
