<mat-sidenav-container class="sidenav-container">
  <!-- <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"> -->
  <!-- [ngClass]="{'hidden': !(isHandset$ | async)}"> -->

  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [opened]="true" [mode]="'side'">

    <mat-toolbar class="header-row">
      <!-- <div fxHide.gt-xs> -->
      <!-- <div>
        <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div> -->
      <div>
        <!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">keyboard_arrow_left</mat-icon>
        </button> -->
        <span style="color: white;">Menu</span>
      </div>
    </mat-toolbar>

    <div class="sub-menu-row"></div>

    <mat-nav-list>
      <a mat-list-item routerLink="/search" routerLinkActive="active">Hledat IBL</a>
      <a mat-list-item routerLink="/create" routerLinkActive="active">Vytvořit IBL</a>
      <a mat-list-item routerLink="/logout" routerLinkActive="active">Zpět do Obchodníka</a>
      <!--<a mat-list-item routerLink="/" routerLinkActive="active" (click)="drawer.toggle(); onAboutClick()">O aplikaci</a>-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="mat-toolbar">
    <mat-toolbar color="primary" class="mat-toolbar header-row">

      <div style="width: 100%;">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" style="width: 100%;">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div>
              <img src="assets/image/logo/logo.svg" style="height: 40px; padding-top: 11px;">
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <div>
                <a routerLink="/logout" routerLinkActive="active">
                  <img src="assets/image/home.svg" style="height: 30px; padding-top: 10px;">
                </a>
              </div>
            </div>
            <div><strong>Obchodník</strong></div>
            <div><strong> - </strong></div>
            <div><strong>Inkasní blok</strong></div>
          </div>

          <div fxFlex fxLayout="column" fxLayoutAlign="center end" fxFlexAlign="end" fxLayoutGap="1em"
            class="user-info">
            <div *ngIf="displayName != null || pz != null" fxLayout="row" fxLayoutAlign="center center"
              fxLayoutGap="20px">
              <div *ngIf="displayName != null">Uživatel: {{ displayName }}</div>
              <div *ngIf="pz != null">Makléř: {{ pz }}</div>
            </div>
          </div>

        </div>

      </div>

    </mat-toolbar>

    <div class="sub-menu-row"></div>

    <router-outlet></router-outlet>
    <!-- <ng-content></ng-content> -->

  </mat-sidenav-content>

</mat-sidenav-container>
