import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../services/auth.service';
import { NGXLogger } from 'ngx-logger';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private configService: AppConfigService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.performLogout();
  }

  public performLogout(): void {
    this.emptyLocalStorageOnLogout();
    this.redirectToObchodnikUrl();
  }

  private emptyLocalStorageOnLogout() {
    this.logger.debug(this.constructor.name + ' emptying localStorage');
    this.authService.setIsUserAuthorized(false);
    localStorage.clear();
    if (this.cookieService.check(this.configService.configuration.obchodnik.cookie.name)) {
      this.cookieService.delete(this.configService.configuration.obchodnik.cookie.name);
    }
  }

  private redirectToObchodnikUrl() {
    window.location.href = this.configService.configuration.obchodnik.url;
  }

}
