import * as objectMapper from 'object-mapper';
import * as moment from 'moment';
import * as i0 from "@angular/core";
export class ObjectMapperService {
    constructor() { }
    mapBackendContractDTOToCollectionBillFormDTO(inputObject) {
        const map = {
            contractId: 'insuranceContractId',
            'insurancePolicyHolder.personalIdentificationOrCompanyRegNumber': 'personalIdentificationOrCompanyRegNumber',
            insurancePolicyHolder: [
                {
                    key: 'client',
                    transform(identity) {
                        const fullName = [identity.name, identity.surname].filter(Boolean).join(' ');
                        return [fullName, identity.company].filter(Boolean).join(' / ');
                    }
                }
            ],
            'insurancePolicyHolder.formattedAddress': 'address',
            'installments.amount': 'amount'
        };
        const src = inputObject;
        return objectMapper(src, map);
    }
    mapCollectionBillFormDTOToBackendCollectionBillDTO(inputObject) {
        const map = {
            insuranceContractId: 'insuranceContractId',
            personalIdentificationOrCompanyRegNumber: 'personalIdentificationOrCompanyRegNumber',
            client: 'client',
            address: 'address',
            amount: 'amount',
            periodFrom: [
                {
                    key: 'periodFrom',
                    transform(periodFrom) {
                        return periodFrom ? moment(periodFrom).format('YYYY-MM-DD') : null;
                    }
                }
            ],
            periodTo: [
                {
                    key: 'periodTo',
                    transform(periodTo) {
                        return periodTo ? moment(periodTo).format('YYYY-MM-DD') : null;
                    }
                }
            ],
            paymentReason: 'paymentReason',
            venue: 'venue',
            note: 'note'
        };
        const src = inputObject;
        return objectMapper(src, map);
    }
}
ObjectMapperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObjectMapperService_Factory() { return new ObjectMapperService(); }, token: ObjectMapperService, providedIn: "root" });
