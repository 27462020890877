<div class="ibl-body">

  <div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="1em">

    <h2 fxFlexAlign="start" class="page-title">Vyhledávání inkasních bloků</h2>

    <form fxFlexAlign="start" [formGroup]="searchForm" (ngSubmit)="searchContract()">
      <div fxLayout="row" fxLayoutAlign="center center" fxFlexAlign="start" fxLayoutGap="1em">

        <div>
          <mat-form-field class="form-row-width-25">
            <input matInput placeholder="Číslo pojistné smlouvy" type="text" formControlName="insuranceContractNumber"
              (keydown.enter)="searchContract()" [maxLength]="10">
            <mat-hint align="start">Vkládejte pouze číselnou hodnotu</mat-hint>
            <mat-error
              *ngIf="searchForm.controls.insuranceContractNumber?.errors && (searchForm.controls.insuranceContractNumber?.touched || searchForm.controls.insuranceContractNumber?.dirty)">
              <!-- <div *ngIf="searchForm.controls.insuranceContractNumber?.errors.required"> -->
              <div>
                <strong>Vkládejte pouze číselnou hodnotu</strong>
              </div>
              <!-- </div> -->
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <button mat-flat-button class="button-margins" color="primary" type="submit"
            [disabled]="isSearchButtonDisabled || !searchForm.valid">Vyhledat</button>
        </div>
        <div>
          <button mat-flat-button class="button-margins" color="accent" (click)="createCollectionBill()" type="button"
            [disabled]="isCreateButtonDisabled">Vytvořit nový inkasní blok</button>
        </div>

      </div>
    </form>

    <div fxFlexAlign="start">
      <mat-card *ngIf="searchErrorMessage" class="card-content-warning">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
          <mat-icon aria-label="IBL search error">error_outline</mat-icon>
          <span>{{ searchErrorMessage }}</span>
        </div>
      </mat-card>
    </div>

    <div fxFlexAlign="start">
      <app-collection-bill-search [hidden]="isSearchResultTableHidden" (searchSuccessMessage)="getSearchSuccess($event)"
        (searchErrorMessage)="getSearchError($event)">
      </app-collection-bill-search>
    </div>

  </div>

</div>
