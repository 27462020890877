import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {

  private OBCHODNIK_COOKIE_NAME: string = this.configService.configuration.obchodnik.cookie.name;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private configService: AppConfigService,
    private router: Router,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.checkAuthorization();
  }

  private checkAuthorization() {
    if (Storage !== void (0)) { // Browser supports local storage

      // Is cookie from obchodnik present? If yes, try authenticate user
      if (this.cookieService.check(this.OBCHODNIK_COOKIE_NAME)) {
        this.logger.debug(this.constructor.name + ' cookie found, try auth user...', this.cookieService.get(this.OBCHODNIK_COOKIE_NAME));
        this.authService.tryAuthenticateUser(this.cookieService.get(this.OBCHODNIK_COOKIE_NAME));

        // else try with current jwt value from local storage, if present
      } else if (localStorage.getItem('jwt')) {
        this.logger.debug(this.constructor.name + ' jwt ls found, try auth user...');
        this.authService.tryAuthenticateUser(localStorage.getItem('jwt'));

        // otherwise logout user forcing him/her to login again via bochodnik
      } else {
        this.logger.debug(this.constructor.name + ' NO cookie found => logout');
        this.router.navigate(['/logout']);
      }

    } else { // Browser does NOT support local storage => logout
      this.logger.debug(this.constructor.name + ' NO support for storage => logout');
      this.router.navigate(['/logout']);
    }
  }

}
