import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CollectionBillComponent } from './collection-bill/collection-bill.component';
import { CollectionBillCreateComponent } from './collection-bill-create/collection-bill-create.component';
import { AuthGuardService } from 'src/app/services/auth-guard.service';


const collectionBillRoutes: Routes = [
  { path: 'search', component: CollectionBillComponent, canActivate: [AuthGuardService] },
  { path: 'create', component: CollectionBillCreateComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(collectionBillRoutes)],
  exports: [RouterModule]
})

export class CollectionBillRoutingModule { }
