import { Injectable } from '@angular/core';
import * as objectMapper from 'object-mapper';
import { InsuranceContractDto } from 'src/app/models/insurance-contract-dto';
import { CollectionBillDto } from 'src/app/models/collection-bill-dto';
import { CollectionBillPostDto } from 'src/app/models/collection-bill-post-dto';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ObjectMapperService {

  constructor() { }

  public mapBackendContractDTOToCollectionBillFormDTO(inputObject: InsuranceContractDto): CollectionBillDto {
    const map = {
      contractId: 'insuranceContractId',
      'insurancePolicyHolder.personalIdentificationOrCompanyRegNumber': 'personalIdentificationOrCompanyRegNumber',
      insurancePolicyHolder: [
        {
          key: 'client',
          transform(identity) {
            const fullName = [identity.name, identity.surname].filter(Boolean).join(' ');
            return [fullName, identity.company].filter(Boolean).join(' / ');
          }
        }
      ],
      'insurancePolicyHolder.formattedAddress': 'address',
      'installments.amount': 'amount'
    };

    const src = inputObject;
    return objectMapper(src, map);
  }

  public mapCollectionBillFormDTOToBackendCollectionBillDTO(inputObject: CollectionBillDto): CollectionBillPostDto {
    const map = {
      insuranceContractId: 'insuranceContractId',
      personalIdentificationOrCompanyRegNumber: 'personalIdentificationOrCompanyRegNumber',
      client: 'client',
      address: 'address',
      amount: 'amount',
      periodFrom: [
        {
          key: 'periodFrom',
          transform(periodFrom: moment.MomentInput) {
            return periodFrom ? moment(periodFrom).format('YYYY-MM-DD') : null;
          }
        }
      ],
      periodTo: [
        {
          key: 'periodTo',
          transform(periodTo: moment.MomentInput) {
            return periodTo ? moment(periodTo).format('YYYY-MM-DD') : null;
          }
        }
      ],
      paymentReason: 'paymentReason',
      venue: 'venue',
      note: 'note'
    };

    const src = inputObject;
    return objectMapper(src, map);
  }
}
