import { FormGroup, ValidatorFn, ValidationErrors, FormControl, AbstractControl } from '@angular/forms';

export class CustomFormValidator {
  static dateCheck(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const periodFrom = formGroup.get('periodFrom');
      const periodTo = formGroup.get('periodTo');

      /**
       * Validations are different based on what paymentReason is selected by user - in most cases the
       * date fields are having required validator set, and in such case the validations are to be done.
       * If date fields are not marked as required the validations are skipped (allowing user to enter
       * basically any value).
       *
       * There is no simple way how to determine if certain form control is having specific validator set.
       * More can be found on following pages:
       * https://github.com/angular/angular/issues/13461
       * https://stackoverflow.com/questions/39819123/angular2-find-out-if-formcontrol-has-required-validator
       */
      if (this.hasRequiredValidator(periodFrom) && this.hasRequiredValidator(periodTo)) {
        // perform validations
        if (periodFrom.value && periodTo.value) {
          if (periodFrom.value > periodTo.value) {
            periodFrom.setErrors({ dateInvalid: true });
            periodTo.setErrors({ dateInvalid: true });
          } else {
            periodFrom.markAsUntouched();
            periodTo.markAsUntouched();
          }
        } else if (periodFrom.value && !periodTo.value) {
          periodFrom.setErrors(null);
          periodTo.setErrors({ dateInvalid: true });
          periodTo.markAsTouched();
        } else if (!periodFrom.value && periodTo.value) {
          periodFrom.setErrors({ dateInvalid: true });
          periodFrom.markAsTouched();
          periodTo.setErrors(null);
        }

      } else if (periodFrom.value || periodTo.value) {
        /**
         * Validations for AmountType.OTHER and any entered value - basically same validations
         * are to be done in case user enters value into any of the date inputs as for other
         * paymentReason types.
         * In this case the fields are not having required validator set to allow the user to leave them blank. However,
         * if date is entered by user, we have to validate it, plus both fields need to be filled by user.
         */
        if (periodFrom.value && periodTo.value) {
          if (periodFrom.value > periodTo.value) {
            periodFrom.setErrors({ dateInvalid: true });
            periodTo.setErrors({ dateInvalid: true });
          } else {
            periodFrom.markAsUntouched();
            periodTo.markAsUntouched();
          }
        } else if (periodFrom.value && !periodTo.value) {
          periodFrom.setErrors(null);
          periodTo.setErrors({ dateInvalid: true });
          periodTo.markAsTouched();
        } else if (!periodFrom.value && periodTo.value) {
          periodFrom.setErrors({ dateInvalid: true });
          periodFrom.markAsTouched();
          periodTo.setErrors(null);
        }

      } else { // skip validations for AmountType.OTHER and NO entered value in date fields
        /**
         * All date validations are skipped in case the date fields values are not entered by
         * user - if both are empty for AmountType.OTHER then this is allowed case, date values
         * are not required in such case. Dates are simply not mandatory.
         */
      }

      return;
    };
  }

  static amtCheck(allowedMin: number, allowedMax: number): ValidatorFn {
    return (formControl: FormControl): ValidationErrors => {
      const enteredAmount = formControl.value;
      if (enteredAmount < allowedMin || enteredAmount > allowedMax) {
        formControl.setErrors({ amountInvalid: true });
        formControl.markAsTouched();
      }
      return;
    };
  }

  static amountCheck(allowedMin: number, allowedMax: number): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const amountControl = formGroup.get('amount');
      if (amountControl.value < allowedMin || amountControl.value > allowedMax) {
        amountControl.setErrors({ amountInvalid: true });
        amountControl.markAsTouched();
      }
      return;
    };
  }

  static hasRequiredValidator(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
    // if (abstractControl['controls']) {
    //   for (const controlName in abstractControl['controls']) {
    //     if (abstractControl['controls'][controlName]) {
    //       if (this.hasRequiredField(abstractControl['controls'][controlName])) {
    //         return true;
    //       }
    //     }
    //   }
    // }
    // return false;
  }

  /**
   * Validates user input in RC/IC field - the input field should accept two types of
   * values, RC (personal birth number) or IC (company identification number), each
   * of them conforms to different rules. See notes within this method for details.
   */
  static personalIdCheck(): ValidatorFn {
    // note: Slavia is using "fake" birth numbers for foreigners, having 1111 or 9999 suffixes
    // https://diskuse.jakpsatweb.cz/?action=vthread&forum=8&topic=153566#6
    // https://phpfashion.com/jak-overit-platne-ic-a-rodne-cislo

    return (formControl: FormControl): ValidationErrors => {

      const rxRC = new RegExp('^\\d{2}[01235678][0-9][0-3][0-9]\/?\\d{3,4}$');
      const rxIC = new RegExp('^\\d{8}$');
      const id = formControl.value;

      if (id && rxRC.test(id)) {
        formControl.setErrors(null);
      } else if (id && rxIC.test(id)) {
        formControl.setErrors(null);
      } else if (id) {
        formControl.setErrors({ idInvalid: true });
        formControl.markAsTouched();
        return { idInvalid: true };
      }

      return;
    };
  }

}

// https://github.com/angular/angular/issues/13461
// https://stackoverflow.com/questions/39819123/angular2-find-out-if-formcontrol-has-required-validator
