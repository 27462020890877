import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./app-config.service";
import * as i3 from "ngx-logger";
export class HttpOutInterceptorService {
    // TODO: Implementation needed - what all functionality we required from outgoing http interceptor?
    constructor(authService, configService, logger) {
        this.authService = authService;
        this.configService = configService;
        this.logger = logger;
    }
    intercept(req, next) {
        this.logger.debug(this.constructor.name + ' about to intercept outgoing request...');
        const jwt = this.authService.getLocalStorageValue(this.configService.configuration.localStorage.jwtKeyName);
        if (jwt) {
            const cloned = req.clone({
                // headers: req.headers.set("Authorization", "Bearer " + jwt)
                setHeaders: {
                    Authorization: 'Bearer ' + jwt,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            this.logger.debug(this.constructor.name + ' outgoing request - added header', cloned);
            return next.handle(cloned);
        }
        else {
            this.logger.debug(this.constructor.name + ' outgoing request untouched - no token added');
            return next.handle(req);
        }
    }
}
HttpOutInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpOutInterceptorService_Factory() { return new HttpOutInterceptorService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.NGXLogger)); }, token: HttpOutInterceptorService, providedIn: "root" });
