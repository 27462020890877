import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  private subscription: Subscription;
  displayName: string;
  pz: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) { }


  ngOnInit() {
    this.subscription = this.authService.isLocalStorageReady.subscribe(event => this.displayUserInfo(event));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private displayUserInfo(event: boolean): void {
    if (event) {
      this.displayName = localStorage.getItem('displayName');
      this.pz = localStorage.getItem('pz');
    }
  }

  onAboutClick() {
    alert('work in progress...');
  }
}
