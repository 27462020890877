import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-logger";
export class AppConfigService {
    constructor(
    // We need HttpBackend for this service to skip http interceptor while
    // loading configuration data on bootstrap, otherwise the interceptor itself cannot get config key
    httpHandler, logger) {
        this.httpHandler = httpHandler;
        this.logger = logger;
        this.configLocation = environment.configLocation;
        this.httpClient = new HttpClient(httpHandler);
    }
    // loadApplicationConfiguration() {
    //   this.logger.debug(this.constructor.name + ' loadApplicationConfiguration from file ', this._configLocation);
    //   return this._httpClient.get<AppConfig>(this._configLocation)
    //     .toPromise()
    //     .then((appConfigJsonContent: AppConfig) => {
    //       // this.configuration = {
    //       //   localStorage: data['localStorage'],
    //       //   obchodnik: data['obchodnik']
    //       // };
    //       this.configuration = appConfigJsonContent;
    //     });
    // }
    loadApplicationConfigurationAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.logger.debug(this.constructor.name + ' loadApplicationConfiguration from file ', this.configLocation);
            const appConfigJsonContent = yield this.httpClient.get(this.configLocation)
                .toPromise();
            this.configuration = appConfigJsonContent;
        });
    }
}
AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.NGXLogger)); }, token: AppConfigService, providedIn: "root" });
