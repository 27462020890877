import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(value: any): any {
    if (value === undefined) {
      return '';
    }

    const n = parseInt(value, 10);

    const rxPattern = /(\d+)(\d{3})/;

    return String(n).replace(/^\d+/, (rxMatchGroup) => {
      let result = rxMatchGroup;
      while (rxPattern.test(result)) {
        result = result.replace(rxPattern, '$1 $2');
      }
      return result;
    });
  }

}
