// application imports - services
import { AppConfigService } from './services/app-config.service';
import { CustomPaginator } from './configuration/custom-paginator-configuration';
const appInitializerFn = (appConfig) => {
    return () => {
        return appConfig.loadApplicationConfigurationAsync();
    };
};
const ɵ0 = appInitializerFn;
const ɵ1 = CustomPaginator();
export class AppModule {
}
export { ɵ0, ɵ1 };
